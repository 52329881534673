<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 09:48:44
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-14 16:01:28
 * @FilePath: \sipingnongzhuan2\src\views\xxgk\xrld.vue
-->
<template>
  <div>
    <div
      v-if="isPhone == 'false'"
      style="
    max-width: 1160px;
    margin: 0px auto !important;"
      class="mt-10 development_historys">
      <div class="team_boxss"
        style="padding-top:20px;padding-bottom:0">
        <div
          class="team_boxss_box">
          <el-row
            :gutter="20">
            <el-col :sm="12"
              :md="8" :lg="8"
              v-for="(item,index) in teamListes1"
              :key="index">
              <el-card
                style="height:200px;margin-bottom:20px"
                class="team_img_card">
                <div
                  style="display:flex"
                  class="team_img_box">
                  <div
                    class="team_img">
                    <img
                      v-if="item.imgUrl"
                      style="height:100%"
                      :src="item.imgUrl">
                  </div>
                  <div
                    style="margin-left:10px">
                    <div
                      class="team_title">
                      {{item.title}}
                    </div>
                    <div
                      class="team_job">
                      {{item.reserve1}}
                    </div>
                    <div
                      class="team_content">
                      {{item.content}}
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="team_boxss"
        style="padding-top: 0">
        <div
          class="team_boxss_box">
          <el-row
            :gutter="20">
            <el-col :sm="12"
              :md="8" :lg="8"
              v-for="(item,index) in teamListes2"
              :key="index">
              <el-card
                style="height:200px;margin-bottom:20px"
                class="team_img_card">
                <!-- :style="{display:'flex',marginTop:isPhone=='false'?'':'0px'}" -->
                <div
                  style="display:flex"
                  class="team_img_box">
                  <div
                    class="team_img">
                    <img
                      style="height:100%;"
                      :src="item.imgUrl">
                  </div>
                  <div
                    style="margin-left:10px">
                    <div
                      class="team_title">
                      {{item.title}}
                    </div>
                    <div
                      class="team_job">
                      {{item.reserve1}}
                    </div>
                    <div
                      class="team_content">
                      {{item.content}}
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 手机端  -->
    <div v-else
      style="
    max-width: 1160px;
    margin: 0px auto !important;"
      class="mt-10 development_historys">
      <div class="team_boxss"
        style="padding-top:20px;padding-bottom:0">
        <div
          class="team_boxss_box">
          <el-row
            :gutter="20">
            <el-col :sm="12"
              :md="8" :lg="8"
              v-for="(item,index) in teamListes1"
              :key="index">
              <el-card
                style="height:200px;margin-bottom:20px;margin-top:0px"
                class="team_img_card">
                <div
                  style="display:flex;margin-top:0"
                  class="team_img_box">
                  <div
                    class="team_img">
                    <img
                      v-if="item.imgUrl"
                      style="height:100%"
                      :src="item.imgUrl">
                  </div>
                  <div
                    style="margin-left:10px">
                    <div
                      class="team_title">
                      {{item.title}}
                    </div>
                    <div
                      class="team_job">
                      {{item.reserve1}}
                    </div>
                    <div
                      class="team_content">
                      {{item.content}}
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="team_boxss"
        style="padding-top: 0">
        <div
          class="team_boxss_box">
          <el-row
            :gutter="20">
            <el-col :sm="12"
              :md="8" :lg="8"
              v-for="(item,index) in teamListes2"
              :key="index">
              <el-card
                style="height:200px;margin-bottom:20px"
                class="team_img_card">
                <div
                  style="display:flex;margin-top:0"
                  class="team_img_box">
                  <div
                    class="team_img">
                    <img
                      style="height:100%;"
                      :src="item.imgUrl">
                  </div>
                  <div
                    style="margin-left:10px">
                    <div
                      class="team_title">
                      {{item.title}}
                    </div>
                    <div
                      class="team_job">
                      {{item.reserve1}}
                    </div>
                    <div
                      class="team_content">
                      {{item.content}}
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPhone: 'false',
      teamListes: [],
      teamListes1: [],
      teamListes2: []
    }
  },
  mounted() {
    if (window.localStorage.getItem('isPhone')) this.isPhone = window.localStorage.getItem('isPhone')
    else this.isPhone = 'false'
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      console.log(this.$route.query.id)
      const obj = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: this.$route.query.id
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        this.loading = false
        this.total = parseInt(res.data.total)
        console.log(res.data.list)
        this.teamListes = res.data.list
        this.teamListes.map(item => {
          if (item.url) {
            this.$set(item, 'imgUrl', res.data.imgUrl + '/' + item.url)
          }
        })
        this.teamListes1 = this.teamListes.slice(0, 2)
        this.teamListes2 = this.teamListes.slice(2, this.teamListes.length)
      })
    }
  }
}
</script>

<style scoped>
</style>