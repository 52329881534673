<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-11-01 14:56:51
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-23 08:46:08
 * @FilePath: \sipingnongzhuan2\src\views\xxgk\index.vue
-->
<template>
  <div
    style="background:#fff;min-height:500px">
    <!-- <List>
    </List> -->
    <!-- <Lsyg
      v-if="$route.path == '/lsyg'">
    </Lsyg> -->
    <Xxjs
      v-if="$route.path == '/xxjs'">
    </Xxjs>
    <Xrld
      v-if="$route.path == '/xrld'">
    </Xrld>
    <Xhxb
      v-if="$route.path == '/xhxb'">
    </Xhxb>
    <List
      v-if="$route.path == '/xxzc' || $route.path == '/xyxw' || $route.path == '/tzgg'">
    </List>
  </div>
</template>
<script>
import List from '../../components/list/index.vue'
// import Lsyg from './lsyg.vue'
import Xxjs from './xxjs.vue'
import Xrld from './xrld.vue'
import Xhxb from './xhxb.vue'
export default {
  components: { List, Xxjs, Xrld, Xhxb },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style>
</style>