<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 09:48:44
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-16 13:59:02
 * @FilePath: \sipingnongzhuan2\src\views\xxgk\xhxb.vue
-->
<template>
  <div>
    <div
      v-if="isPhone == 'false'"
      style="background: rgb(255, 255, 255);
      overflow: hidden;
    max-width: 1160px;
    text-align: center;
    margin: 0px auto !important;"
      class="mt-10 development_historys">
      <img
        style="margin:30px 50px;"
        src="../../assets/imgaes/only.png"
        alt="">
      <img
        style="margin:30px 0;float: right;"
        src="../../assets/imgaes/bg_zi2.png"
        alt="">
    </div>
    <div v-else style="background: rgb(255, 255, 255);
      overflow: hidden;
    max-width: 1160px;
    text-align: center;
    margin: 0px auto !important;
    "
      class="mt-10 development_historys">
      <img
        style="width: 100%;margin:20px 0"
        src="../../assets/imgaes/only.png"
        alt="">
      <img
        src="../../assets/imgaes/bg_zi2.png"
        alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPhone: 'false',
      newsList: []
    }
  },
  mounted() {
    if (window.localStorage.getItem('isPhone')) this.isPhone = window.localStorage.getItem('isPhone')
    else this.isPhone = 'false'
    this.getList()
  },
  methods: {
    getList() {
      console.log(this.$route.query.id)
      const obj = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: this.$route.query.id
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        this.newsList = res.data.list
        this.newsList.map(item => {
          this.$set(item, 'imgUrl', res.data.imgUrl + '/' + item.url)
        })
      })
    }
  }
}
</script>

<style scoped>
</style>