<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 09:48:44
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-14 15:10:33
 * @FilePath: \sipingnongzhuan2\src\views\xxgk\xxjs.vue
-->
<template>
  <div
    style="
    padding:0 5px;
    background: rgb(255, 255, 255);
    overflow: hidden;
    max-width: 1160px;
    margin: 0px auto !important;">
    <div class="imgJs"
      v-html="newsList">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyProfile: {},
      imgUrl: '',
      newsList: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      console.log(this.$route.query.id)
      const obj = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: this.$route.query.id
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        this.loading = false
        this.total = parseInt(res.data.total)
        console.log(res.data.list)
        if (res.data.list.length > 0) {
          this.newsList = res.data.list[0].content
        }
        // this.newsList.map(item => {
        //   this.$set(item, 'imgUrl', res.data.imgUrl + '/' + item.url)
        // })
      })
    }
  }
}
</script>

<style>
.imgJs img {
  /* max-width: calc(100% -10px); */
  width: 100%;
}
font[size='1'] {
  font-size: 14px !important;
}
font[size='2'] {
  font-size: 15px !important;
}
font[size='3'] {
  font-size: 16px !important;
}
font[size='4'] {
  font-size: 18px !important;
}
font[size='5'] {
  font-size: 24px !important;
}
font[size='6'] {
  font-size: 32px !important;
}
.contant div p {
  margin-bottom: 20px;
}
</style>